




var ColorList; (function (ColorList) {
  const Blue08 = 'Blue08'; ColorList["Blue08"] = Blue08;
  const Blue15 = 'Blue15'; ColorList["Blue15"] = Blue15;
  const Blue20 = 'Blue20'; ColorList["Blue20"] = Blue20;
  const Blue30 = 'Blue30'; ColorList["Blue30"] = Blue30;
  const Blue40 = 'Blue40'; ColorList["Blue40"] = Blue40;
  const Blue50 = 'Blue50'; ColorList["Blue50"] = Blue50;
  const Blue60 = 'Blue60'; ColorList["Blue60"] = Blue60;
  const Blue70 = 'Blue70'; ColorList["Blue70"] = Blue70;
  const Blue80 = 'Blue80'; ColorList["Blue80"] = Blue80;
  const Blue90 = 'Blue90'; ColorList["Blue90"] = Blue90;
  const Blue100 = 'Blue100'; ColorList["Blue100"] = Blue100;
  const Chilli90 = 'Chilli90'; ColorList["Chilli90"] = Chilli90;
  const Chilli60 = 'Chilli60'; ColorList["Chilli60"] = Chilli60;
  const Chilli40 = 'Chilli40'; ColorList["Chilli40"] = Chilli40;
  const Chilli15 = 'Chilli15'; ColorList["Chilli15"] = Chilli15;
  const Mustard90 = 'Mustard90'; ColorList["Mustard90"] = Mustard90;
  const Mustard60 = 'Mustard60'; ColorList["Mustard60"] = Mustard60;
  const Mustard40 = 'Mustard40'; ColorList["Mustard40"] = Mustard40;
  const Mustard15 = 'Mustard15'; ColorList["Mustard15"] = Mustard15;
  const Green90 = 'Green90'; ColorList["Green90"] = Green90;
  const Green60 = 'Green60'; ColorList["Green60"] = Green60;
  const Green40 = 'Green40'; ColorList["Green40"] = Green40;
  const Green15 = 'Green15'; ColorList["Green15"] = Green15;
  const Gray90 = 'Gray90'; ColorList["Gray90"] = Gray90;
  const Gray60 = 'Gray60'; ColorList["Gray60"] = Gray60;
  const Gray40 = 'Gray40'; ColorList["Gray40"] = Gray40;
  const Gray15 = 'Gray15'; ColorList["Gray15"] = Gray15;
  const Teal90 = 'Teal90'; ColorList["Teal90"] = Teal90;
  const Teal60 = 'Teal60'; ColorList["Teal60"] = Teal60;
  const Teal40 = 'Teal40'; ColorList["Teal40"] = Teal40;
  const Teal15 = 'Teal15'; ColorList["Teal15"] = Teal15;
  const Lavender90 = 'Lavender90'; ColorList["Lavender90"] = Lavender90;
  const Lavender60 = 'Lavender60'; ColorList["Lavender60"] = Lavender60;
  const Lavender40 = 'Lavender40'; ColorList["Lavender40"] = Lavender40;
  const Lavender15 = 'Lavender15'; ColorList["Lavender15"] = Lavender15;
  const Ruby90 = 'Ruby90'; ColorList["Ruby90"] = Ruby90;
  const Ruby60 = 'Ruby60'; ColorList["Ruby60"] = Ruby60;
  const Ruby40 = 'Ruby40'; ColorList["Ruby40"] = Ruby40;
  const Ruby15 = 'Ruby15'; ColorList["Ruby15"] = Ruby15;
})(ColorList || (ColorList = {}));

var ColorPalletList; (function (ColorPalletList) {
  const Primary = 'Primary'; ColorPalletList["Primary"] = Primary;
  const Secondary = 'Secondary'; ColorPalletList["Secondary"] = Secondary;
  const Accent = 'Accent'; ColorPalletList["Accent"] = Accent;
  const Background = 'Background'; ColorPalletList["Background"] = Background;
  const Error = 'Error'; ColorPalletList["Error"] = Error;
  const Warning = 'Warning'; ColorPalletList["Warning"] = Warning;
  const Success = 'Success'; ColorPalletList["Success"] = Success;
  const Info = 'Info'; ColorPalletList["Info"] = Info;
  const Inactive = 'Inactive'; ColorPalletList["Inactive"] = Inactive;
  const Teal = 'Teal'; ColorPalletList["Teal"] = Teal;
  const Lavender = 'Lavender'; ColorPalletList["Lavender"] = Lavender;
  const Ruby = 'Ruby'; ColorPalletList["Ruby"] = Ruby;
  const Chili = 'Chili'; ColorPalletList["Chili"] = Chili;
  const Mustard = 'Mustard'; ColorPalletList["Mustard"] = Mustard;
  const Green = 'Green'; ColorPalletList["Green"] = Green;
  const Blue = 'Blue'; ColorPalletList["Blue"] = Blue;
  const Gray = 'Gray'; ColorPalletList["Gray"] = Gray;
  const Brand = 'Brand'; ColorPalletList["Brand"] = Brand;
})(ColorPalletList || (ColorPalletList = {}));

export const Color = {
  /* Primary Colors */
  [ColorList.Blue08]: { bgColor: '#EDF4FD', color: '#003D79' },
  [ColorList.Blue15]: { bgColor: '#DDEAFB', color: '#003D79' },
  [ColorList.Blue20]: { bgColor: '#D2E3F9', color: '#003D79' },
  [ColorList.Blue30]: { bgColor: '#BBD5F7', color: '#003D79' },
  [ColorList.Blue40]: { bgColor: '#A4C7F4', color: '#003D79' },
  [ColorList.Blue50]: { bgColor: '#8EB9F1', color: '#003D79' },
  [ColorList.Blue60]: { bgColor: '#1C72E3', color: '#FFFFFF' },
  [ColorList.Blue70]: { bgColor: '#1861C2', color: '#FFFFFF' },
  [ColorList.Blue80]: { bgColor: '#004E9A', color: '#FFFFFF' },
  [ColorList.Blue90]: { bgColor: '#003B75', color: '#FFFFFF' },
  [ColorList.Blue100]: { bgColor: '#00264B', color: '#FFFFFF' },

  /* Messaging colors */
  [ColorList.Chilli90]: { bgColor: '#6B0604', color: '#FFFFFF' },
  [ColorList.Chilli60]: { bgColor: '#A10908', color: '#FFFFFF' },
  [ColorList.Chilli40]: { bgColor: '#DD1513', color: '#FFFFFF' },
  [ColorList.Chilli15]: { bgColor: '#F4DBDB', color: '#6B0604' },
  [ColorList.Mustard90]: { bgColor: '#645200', color: '#FFFFFF' },
  [ColorList.Mustard60]: { bgColor: '#8C7200', color: '#FFFFFF' },
  [ColorList.Mustard40]: { bgColor: '#D7AF00', color: '#FFFFFF' },
  [ColorList.Mustard15]: { bgColor: '#F9F3D9', color: '#645200' },
  [ColorList.Green90]: { bgColor: '#2A5B31', color: '#FFFFFF' },
  [ColorList.Green60]: { bgColor: '#008A12', color: '#FFFFFF' },
  [ColorList.Green40]: { bgColor: '#5BB45E', color: '#FFFFFF' },
  [ColorList.Green15]: { bgColor: '#E7F4E7', color: '#2A5B31' },
  [ColorList.Gray90]: { bgColor: '#58595B', color: '#FFFFFF' },
  [ColorList.Gray60]: { bgColor: '#6D6E71', color: '#FFFFFF' },
  [ColorList.Gray40]: { bgColor: '#A7A9AC', color: '#FFFFFF' },
  [ColorList.Gray15]: { bgColor: '#F2F2F3', color: '#58595B' },
  /* Secondary colors */
  [ColorList.Teal90]: { bgColor: '#356766', color: '#FFFFFF' },
  [ColorList.Teal60]: { bgColor: '#21837F', color: '#FFFFFF' },
  [ColorList.Teal40]: { bgColor: '#5CACAA', color: '#FFFFFF' },
  [ColorList.Teal15]: { bgColor: '#E7F3F2', color: '#356766' },
  [ColorList.Lavender90]: { bgColor: '#54466C', color: '#FFFFFF' },
  [ColorList.Lavender60]: { bgColor: '#6F4AB0', color: '#FFFFFF' },
  [ColorList.Lavender40]: { bgColor: '#9983BE', color: '#FFFFFF' },
  [ColorList.Lavender15]: { bgColor: '#F0EDF5', color: '#54466C' },
  [ColorList.Ruby90]: { bgColor: '#90485D', color: '#FFFFFF' },
  [ColorList.Ruby60]: { bgColor: '#C2486B', color: '#FFFFFF' },
  [ColorList.Ruby40]: { bgColor: '#DC7995', color: '#FFFFFF' },
  [ColorList.Ruby15]: { bgColor: '#FAEBEF', color: '#90485D' },
};

export const ColorPallet = {
  [ColorPalletList.Brand]: Color.Blue08,
  [ColorPalletList.Primary]: Color.Blue90,
  [ColorPalletList.Secondary]: Color.Blue70,
  [ColorPalletList.Accent]: Color.Blue60,
  [ColorPalletList.Background]: Color.Blue08,
  [ColorPalletList.Error]: Color.Chilli60,
  [ColorPalletList.Warning]: Color.Mustard60,
  [ColorPalletList.Success]: Color.Green60,
  [ColorPalletList.Info]: Color.Blue80,
  [ColorPalletList.Inactive]: Color.Gray60,
  [ColorPalletList.Teal]: Color.Teal60,
  [ColorPalletList.Lavender]: Color.Lavender60,
  [ColorPalletList.Ruby]: Color.Ruby60,
  [ColorPalletList.Chili]: Color.Chilli60,
  [ColorPalletList.Mustard]: Color.Mustard60,
  [ColorPalletList.Green]: Color.Green60,
  [ColorPalletList.Blue]: Color.Blue80,
  [ColorPalletList.Gray]: Color.Gray60,
};
