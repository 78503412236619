

export const getTechRadarData = async () => {
  const [rings, quadrants, entries] = await Promise.all([
    getRings(),
    getQuadrants(),
    getEntries(),
  ]);
  return {
    rings,
    quadrants: [quadrants[2], quadrants[1], quadrants[0], quadrants[3]],
    entries
  };
}

export async function getRings() {
  return [
    {name: 'Adopt', color: '#0d2064', id: 'adopt'},
    {name: 'Trial', color: '#1736ab', id: 'trial'},
    {name: 'Assess', color: '#204fec', id: 'assess'},
    {name: 'Drop', color: '#ff0c0c', id: 'drop'},
  ];
}

export async function getQuadrants() {
  return [
    {id: 'techniques', name: 'Techniques'},
    {id: 'languages-and-frameworks', name: 'Languages & Frameworks'},
    {id: 'platforms', name: 'Platforms'},
    {id: 'tools', name: 'Tools'},
  ];
}

export const convertToTitleCase = (title) => title.split(' ').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' ');
export const createEntry = (name, url, quadrant, ring, movement) => ({
  id: name.toLowerCase(),
  key: name.toLowerCase(),
  title: convertToTitleCase(name),
  quadrant: quadrant.id,
  url: url || '',
  timeline: [{date: new Date(2021, 4, 1), description: '', moved: movement, ringId: ring.id}]
})

export async function getEntries() {
  const quadrants = await getQuadrants();
  const rings = await getRings();
  return [
    // Techniques adopt
    createEntry('gateway pattern', '', quadrants[0], rings[0], 0),
    createEntry('event driven architectures', '', quadrants[0], rings[0], 1),
    createEntry('microservices', '', quadrants[0], rings[0], 1),
    createEntry('serverless', 'https://serverless.io', quadrants[0], rings[0], 1),
    createEntry('Scrum', 'https://scrum.org', quadrants[0], rings[0], 1),

    // Languages and frameworks adopt
    createEntry('nestjs', 'https://nestjs.com', quadrants[1], rings[0], 1),
    createEntry('angular', 'https://angular.io', quadrants[1], rings[0], 0),
    createEntry('typescript', 'http://typescriptlang.org', quadrants[1], rings[0], 1),
    createEntry('spring', 'https://spring.io', quadrants[1], rings[0], 0),
    createEntry('java', 'https://java.com/nl/', quadrants[1], rings[0], 1),
    createEntry('twitter bootstrap', 'https://bootstrap.com', quadrants[1], rings[0], -1),
    createEntry('json', 'https://json.org', quadrants[1], rings[0], 0),
    createEntry('jest', 'https://jestjs.io', quadrants[1], rings[0], 1),
    createEntry('cypress', 'https://cypress.io', quadrants[1], rings[0], 1),
    createEntry('graphQL', 'https://graphql.org', quadrants[1], rings[0], 1),

    // Platforms adopt
    createEntry('aws', 'http://aws.com', quadrants[2], rings[0], 0),
    createEntry('confluence', 'https://theapsgroup.atlassian.net', quadrants[2], rings[0], 0),
    createEntry('kubernetes', 'https://kubernetes.io', quadrants[2], rings[0], 1),
    createEntry('keycloak', 'https://www.keycloak.org', quadrants[2], rings[0], 0),
    createEntry('mongoDB atlas', 'https://www.mongodb.com', quadrants[2], rings[0], 0),
    createEntry('invision', 'https://www.invisionapp.com', quadrants[2], rings[0], 0),
    createEntry('alfresco', 'https://www.alfresco.com', quadrants[2], rings[0], -1),
    createEntry('RDS using Postgres', 'https://aws.amazon.com/rds', quadrants[2], rings[0], 0),

    // Tools adopt
    createEntry('vault', 'https://vault.prod-apsmos.com/', quadrants[3], rings[0], 0),
    createEntry('terraform', 'https://www.terraform.io/', quadrants[3], rings[0], 0),
    createEntry('gitlab community edition', 'https://gitlab.apsmos.com/', quadrants[3], rings[0], 0),
    createEntry('opendistro', 'https://opendistro.github.io/', quadrants[3], rings[0], 1),
    createEntry('node', 'https://www.nodejs.org', quadrants[3], rings[0], 1),
    createEntry('git', 'https://git-scm.com', quadrants[3], rings[0], 1),
    createEntry('keycloak', 'https://www.keycloak.org', quadrants[3], rings[0], 1),
    createEntry('teams', 'https://teams.microsoft.com', quadrants[3], rings[0], 0),
    createEntry('adobe creative cloud', 'https://www.adobe.com/nl/creativecloud.html', quadrants[3], rings[0], 0),
    createEntry('docker', 'https://docker.com/', quadrants[3], rings[0], 0),
    createEntry('stories on board', 'https://storiesonboard.com/', quadrants[3], rings[0], -1),
    createEntry('Clickup foundations space', 'https://app.clickup.com/2189485/v/fm/22u5d-15513', quadrants[3], rings[0], 1),

    // Techniques adopt
    createEntry('UBL', 'https://nl.wikipedia.org/wiki/Universal_Business_Language', quadrants[0], rings[1], 0),
    createEntry('CQRS', 'https://martinfowler.com/bliki/CQRS.html', quadrants[0], rings[1], 1),
    createEntry('Redux', 'https://ngrx.io/', quadrants[0], rings[1], 1),
    createEntry(
      'Onion Architecture',
      'https://www.codeguru.com/csharp/csharp/cs_misc/designtechniques/understanding-onion-architecture.html',
      quadrants[0],
      rings[1],
      0
    ),
    createEntry('C4', 'https://c4model.com/', quadrants[0], rings[1], 1),
    createEntry('Design Thinking', 'https://www.interaction-design.org/literature/article/what-is-design-thinking-and-why-is-it-so-popular', quadrants[0], rings[1], 1),
    createEntry('Event Storming', 'https://www.lucidchart.com/blog/ddd-event-storming', quadrants[0], rings[1], 1),
    createEntry('ADR', 'https://adr.github.io/', quadrants[0], rings[1], 1),
    createEntry('Team Topologies', 'https://teamtopologies.com/book', quadrants[0], rings[1], 1),
    createEntry('Observability', 'https://en.wikipedia.org/wiki/Observability', quadrants[0], rings[1], 0),
    createEntry('Lightning Talks', 'https://en.wikipedia.org/wiki/Lightning_talk#:~:text=A%20lightning%20talk%20is%20a,sometimes%20called%20a%20data%20blitz.', quadrants[0], rings[1], 0),
    createEntry('Serverless', 'https://en.wikipedia.org/wiki/Serverless_computing', quadrants[0], rings[1], 1),

    // Languages and frameworks trial
    createEntry('Gatsby', 'https://www.gatsbyjs.com/', quadrants[1], rings[1], 1),
    createEntry('React', 'https://reactjs.org/', quadrants[1], rings[1], 1),
    createEntry('Dashkit', 'https://dashkit.goodthemes.co/', quadrants[1], rings[1], -1),
    createEntry('NGX-Bootstrap', 'https://valor-software.com/ngx-bootstrap/#/', quadrants[1], rings[1], -1),

    // Tools trial
    createEntry('Sonar', 'https://www.sonarqube.org', quadrants[3], rings[1], 1),
    createEntry('Sentry', 'https://www.sentry.io', quadrants[3], rings[1], 1),
    createEntry('Detectify', 'http://detectify.com/', quadrants[3], rings[1], 0),
    createEntry('Invision DSM/Craft', 'https://www.invisionapp.com/', quadrants[3], rings[1], 1),
    createEntry('Conventional commits', 'https://www.conventionalcommits.org/en/v1.0.0/', quadrants[3], rings[1], 1),
    createEntry('K9S', 'https://k9scli.io/', quadrants[3], rings[1], 1),
    createEntry('Miro', 'https://miro.com/', quadrants[3], rings[1], 1),
    createEntry('Docker Compose', 'https://docs.docker.com/compose/', quadrants[3], rings[1], -1),

    // Techniques assess
    createEntry('Event Sourcing', 'https://www.eventstore.com/blog/what-is-event-sourcing', quadrants[0], rings[2], 1),
    createEntry('Forum Engagement', 'https://en.wikipedia.org/wiki/Forum_(Roman)', quadrants[0], rings[2], 1),
    createEntry('Performance Management', 'https://blog.epaysystems.com/talent-management-vs.-performance-management#:~:text=Talent%20management%20focuses%20on%20the,productivity%20and%20accomplish%20organizational%20goals.', quadrants[0], rings[2], 0),
    createEntry('Talent Management', 'https://blog.epaysystems.com/talent-management-vs.-performance-management#:~:text=Talent%20management%20focuses%20on%20the,productivity%20and%20accomplish%20organizational%20goals.', quadrants[0], rings[2], 0),
    createEntry('ArchiMate', 'https://en.wikipedia.org/wiki/ArchiMate', quadrants[0], rings[2], 0),
    createEntry('UML', 'https://www.uml.org/', quadrants[0], rings[2], -1),
    createEntry(
      'Data Contracts',
      'https://docs.microsoft.com/en-us/dotnet/framework/wcf/feature-details/using-data-contracts',
      quadrants[0],
      rings[2],
      1
    ),

    // Languages and frameworks assess
    createEntry('Micronaut', 'https://micronaut.io/', quadrants[1], rings[2], 0),
    createEntry('Cypress', 'https://www.cypress.io/', quadrants[1], rings[2], 1),
    createEntry('Lighthouse', 'https://developers.google.com/web/tools/lighthouse', quadrants[1], rings[2], 1),
    createEntry('Cucumber', 'https://cucumber.io/', quadrants[1], rings[2], 0),
    createEntry('Module federation', 'https://webpack.js.org/concepts/module-federation/', quadrants[1], rings[2], 1),
    createEntry('Eslint', 'https://eslint.org/', quadrants[1], rings[2], 1),
    createEntry('Independent UI components', 'https://developer.mozilla.org/en-US/docs/Web/Web_Components', quadrants[1], rings[2], 1),

    // platforms assess
    createEntry('Keybase', 'https://keybase.io/', quadrants[2], rings[2], 0),
    createEntry('Backstage', 'http://developer.prod-apsmos.com/', quadrants[2], rings[2], 1),
    createEntry('EventStore', 'https://evenstore.com/', quadrants[2], rings[2], 1),
    createEntry('Apollo Studio', 'https://studio.apollographql.com', quadrants[2], rings[2], 0),

    // Tools assess
    createEntry('aps/cli', 'https://gitlab.apsmos.com/aps/devkit/cli', quadrants[3], rings[2], 1),
    createEntry(
      'Backstage software templates',
      'https://backstage.io/docs/features/software-templates/software-templates-index',
      quadrants[3],
      rings[2],
      1
    ),
    createEntry('Backstage tech radar', 'https://backstage.io/blog/2020/05/14/tech-radar-plugin', quadrants[3], rings[2], 1),
    createEntry('Backstage k8s plugin', 'https://backstage.io/blog/2021/01/12/new-backstage-feature-kubernetes-for-service-owners', quadrants[3], rings[2], 1),
    createEntry('Cypress dashboard', 'https://www.cypress.io/dashboard/', quadrants[3], rings[2], 1),
    createEntry('Figma', 'https://www.figma.com/', quadrants[3], rings[2], 0),
    createEntry('Storybook', 'https://storybook.js.org/', quadrants[3], rings[2], 0),
    createEntry('Qase', 'https://qase.io/', quadrants[3], rings[2], 0),
    createEntry('Sketch', 'https://www.sketch.com/', quadrants[3], rings[2], 0),
    createEntry('Filestage', 'https://filestage.io/', quadrants[3], rings[2], 1),
    createEntry('Marp', 'https://marp.app/', quadrants[3], rings[2], 1),
    createEntry('Kibana', 'http://kibana.prod-apsmos.com/', quadrants[3], rings[2], 0),
    createEntry('Terraform CDK', 'https://learn.hashicorp.com/tutorials/terraform/cdktf', quadrants[3], rings[2], 1),
    createEntry('Gitlab Enterprise', 'https://about.gitlab.com/enterprise/', quadrants[3], rings[2], 1),
    createEntry('Lokalize', 'https://lokalise.com//', quadrants[3], rings[2], 0),

    // Techniques drop
    createEntry('Monoliths', 'https://en.wikipedia.org/wiki/Monolithic_application', quadrants[0], rings[3], 0),
    createEntry('Git flow', 'https://datasift.github.io/gitflow/IntroducingGitFlow.htmlm', quadrants[0], rings[3], -1),

    // Languages and Framework drop
    createEntry('AngularJS', 'https://angularjs.org/', quadrants[1], rings[3], 0),
    createEntry('Tapestry', 'https://tapestry.apache.org/component-templates.html', quadrants[1], rings[3], 0),
    createEntry('Tslint', 'https://github.com/palantir/tslint/issues/4534', quadrants[1], rings[3], -1),
    createEntry('JQuery', 'http://youmightnotneedjquery.com/', quadrants[1], rings[3], -1),

    // Platforms drop
    createEntry('Node4', 'https://www.node4.co.uk/', quadrants[2], rings[3], 0),

    // Tools drop
    createEntry('Notepad++', 'https://notepad-plus-plus.org/downloads/', quadrants[3], rings[3], 0),
    createEntry('Eclipse', 'https://www.eclipse.org/ide/', quadrants[3], rings[3], 0),
    createEntry('Fogbugs', 'https://fb.apsmos.com/login', quadrants[3], rings[3], 0),
    createEntry('Invision Studio', 'https://www.invisionapp.com/studio', quadrants[3], rings[3], 0),
    createEntry('Datadog', 'https://www.datadoghq.com/', quadrants[3], rings[3], 0),
  ];
}
