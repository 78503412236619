 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }















import { WarningPanel } from '@backstage/core';
import {
  ApiDefinitionCard,
  ConsumedApisCard,
  ConsumingComponentsCard,
  ProvidedApisCard,
  ProvidingComponentsCard
} from '@backstage/plugin-api-docs';
import {
  AboutCard, EntityPageLayout,
} from '@backstage/plugin-catalog';
import {
  useEntity
} from '@backstage/plugin-catalog-react';
import {
  isPluginApplicableToEntity as isGitHubActionsAvailable, Router as GitHubActionsRouter
} from '@backstage/plugin-github-actions';
import { SentryIssuesWidget } from '@backstage/plugin-sentry';
import { EmbeddedDocsRouter as DocsRouter } from '@backstage/plugin-techdocs';
import { Grid } from '@material-ui/core';
import React from 'react';


const CICDSwitcher = ({ entity }) => {
  // This component is just an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  switch (true) {
    case isGitHubActionsAvailable(entity):
      return React.createElement(GitHubActionsRouter, { entity: entity,} );
    default:
      return (
        React.createElement(WarningPanel, { title: "CI/CD switcher:" ,}, "No CI/CD is available for this entity. Check corresponding annotations!"


        )
      );
  }
};

const OverviewContent = ({ entity }) => (
  React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
    , React.createElement(Grid, { item: true,}
      , React.createElement(AboutCard, { entity: entity, variant: "gridItem",} )
      , React.createElement(SentryIssuesWidget, { entity: entity,} )
    )
  )
);

const ComponentApisContent = ({ entity }) => (
  React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
    , React.createElement(Grid, { item: true, md: 6,}
      , React.createElement(ProvidedApisCard, { entity: entity,} )
    )
    , React.createElement(Grid, { item: true, md: 6,}
      , React.createElement(ConsumedApisCard, { entity: entity,} )
    )
  )
);

const ServiceEntityPage = ({ entity }) => (
  React.createElement(EntityPageLayout, null
    , React.createElement(EntityPageLayout.Content, {
      path: "/",
      title: "Overview",
      element: React.createElement(OverviewContent, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/ci-cd/*",
      title: "CI/CD",
      element: React.createElement(CICDSwitcher, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/api/*",
      title: "API",
      element: React.createElement(ComponentApisContent, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/docs/*",
      title: "Docs",
      element: React.createElement(DocsRouter, { entity: entity,} ),}
    )
  )
);

const WebsiteEntityPage = ({ entity }) => (
  React.createElement(EntityPageLayout, null
    , React.createElement(EntityPageLayout.Content, {
      path: "/",
      title: "Overview",
      element: React.createElement(OverviewContent, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/ci-cd/*",
      title: "CI/CD",
      element: React.createElement(CICDSwitcher, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/docs/*",
      title: "Docs",
      element: React.createElement(DocsRouter, { entity: entity,} ),}
    )
  )
);

const DefaultEntityPage = ({ entity }) => (
  React.createElement(EntityPageLayout, null
    , React.createElement(EntityPageLayout.Content, {
      path: "/*",
      title: "Overview",
      element: React.createElement(OverviewContent, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/docs/*",
      title: "Docs",
      element: React.createElement(DocsRouter, { entity: entity,} ),}
    )
  )
);

export const ComponentEntityPage = ({ entity }) => {
  switch (_optionalChain([entity, 'optionalAccess', _ => _.spec, 'optionalAccess', _2 => _2.type])) {
    case 'service':
      return React.createElement(ServiceEntityPage, { entity: entity,} );
    case 'website':
      return React.createElement(WebsiteEntityPage, { entity: entity,} );
    default:
      return React.createElement(DefaultEntityPage, { entity: entity,} );
  }
};

const ApiOverviewContent = ({ entity }) => (
  React.createElement(Grid, { container: true, spacing: 3,}
    , React.createElement(Grid, { item: true, md: 6,}
      , React.createElement(AboutCard, { entity: entity,} )
    )
    , React.createElement(Grid, { container: true, item: true, md: 12,}
      , React.createElement(Grid, { item: true, md: 6,}
        , React.createElement(ProvidingComponentsCard, { entity: entity,} )
      )
      , React.createElement(Grid, { item: true, md: 6,}
        , React.createElement(ConsumingComponentsCard, { entity: entity,} )
      )
    )
  )
);

const ApiDefinitionContent = ({ entity }) => (
  React.createElement(Grid, { container: true, spacing: 3,}
    , React.createElement(Grid, { item: true, xs: 12,}
      , React.createElement(ApiDefinitionCard, { apiEntity: entity,} )
    )
  )
);

const ApiEntityPage = ({ entity }) => (
  React.createElement(EntityPageLayout, null
    , React.createElement(EntityPageLayout.Content, {
      path: "/*",
      title: "Overview",
      element: React.createElement(ApiOverviewContent, { entity: entity,} ),}
    )
    , React.createElement(EntityPageLayout.Content, {
      path: "/definition/*",
      title: "Definition",
      element: React.createElement(ApiDefinitionContent, { entity: entity ,} ),}
    )
  )
);

export const EntityPage = () => {
  const { entity } = useEntity();

  switch (_optionalChain([entity, 'optionalAccess', _3 => _3.kind, 'optionalAccess', _4 => _4.toLowerCase, 'call', _5 => _5()])) {
    case 'component':
      return React.createElement(ComponentEntityPage, { entity: entity,} );
    case 'api':
      return React.createElement(ApiEntityPage, { entity: entity,} );
    default:
      return React.createElement(DefaultEntityPage, { entity: entity,} );
  }
};
