/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 50,
  },
  div: {
    color: 'white',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    React.createElement('div', { className: classes.div,}
      , React.createElement('img', { src: "/aps-logo.svg", alt: "APS Group Logo"  , className: classes.img,} )
    )
  );
};

export default LogoFull;
