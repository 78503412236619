import {
  AlertDisplay,
  createApp,
  FlatRoutes,
  oauth2ApiRef,
  OAuthRequestDialog,
  SidebarPage,
  SignInPage
} from '@backstage/core';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {SearchPage} from '@backstage/plugin-search';
import {TechdocsPage} from '@backstage/plugin-techdocs';
import React from 'react';
import {Navigate, Route} from 'react-router';
import {apis} from './apis';
import {EntityPage} from './components/catalog/EntityPage';
import * as plugins from './plugins';
import {AppSidebar} from './sidebar';
import {apsTheme} from './apsTheme';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { getTechRadarData } from "./tech-radar";

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
    bind(apiDocsPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
  },
  themes: apsTheme,
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, {
        ...props,
        auto: true,
        provider: {
          id: 'oauth2-auth-provider',
          title: 'Please sign in',
          apiRef: oauth2ApiRef,
        },}
      )
    )
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null)
    , React.createElement(OAuthRequestDialog, null)
    , React.createElement(AppRouter, null
      , React.createElement(SidebarPage, null
        , React.createElement(AppSidebar, null)
        , React.createElement(FlatRoutes, null
          , React.createElement(Navigate, { key: "/", to: "/catalog",})
          , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),})
          , React.createElement(Route, {
            path: "/catalog/:namespace/:kind/:name",
            element: React.createElement(CatalogEntityPage, null),}
          
          , React.createElement(EntityPage, null)
          )
          , React.createElement(Route, { path: "/docs", element: React.createElement(TechdocsPage, null),})
          , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null),})
          , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null),})
          , React.createElement(Route, {
            path: "/tech-radar",
            element: React.createElement(TechRadarPage, { pageTitle: "APS Group Radar"  , getData: getTechRadarData, width: 1500, height: 800,} ),}
          )
          , React.createElement(Route, { path: "/catalog-import", element: React.createElement(CatalogImportPage, null),})
          , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null),})
        )
      )
    )
  )
);

export default App;
