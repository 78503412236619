import React, { useContext } from 'react';
import CategoryIcon from '@material-ui/icons/Category';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import TrackChanges from '@material-ui/icons/TrackChanges';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

import {
  configApiRef,
  Sidebar,
  SidebarItem,
  SidebarDivider,
  sidebarConfig,
  SidebarContext,
  SidebarSpace,
  useApi,
} from '@backstage/core';
import { SidebarSearch } from '@backstage/plugin-search';


const useSidebarLogoStyles = (isOpen) => makeStyles({
  root: {
    width: isOpen ? sidebarConfig.drawerWidthOpen : sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    margin: '0 auto'
  },
})();

const SidebarLogo = () => {
  const { isOpen } = useContext(SidebarContext);
  const classes = useSidebarLogoStyles(isOpen);

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, {
        component: NavLink,
        to: "/",
        underline: "none",
        className: classes.link,}
      
        , isOpen ? React.createElement(LogoFull, null ) : React.createElement(LogoIcon, null )
      )
    )
  );
};

export const SidebarSignoutComponent = () => {
  const configApi = useApi(configApiRef);

  const logout = () => {
    const baseUrl = configApi.getString('backend.baseUrl');
    location.replace(`${baseUrl}/sign-out`);
  }

  return React.createElement(SidebarItem, { icon: ExitToAppIcon, onClick: logout, text: "Sign out" ,} )
}

export const AppSidebar = () => (
  React.createElement(Sidebar, null
    , React.createElement(SidebarLogo, null )
    , React.createElement(SidebarSearch, null )
    /* Global nav, not org-specific */
    , React.createElement(SidebarItem, { icon: CategoryIcon, to: "./", text: "Catalog",} )
    , React.createElement(SidebarItem, { icon: LibraryBooks, to: "/docs", text: "Docs",} )
    , React.createElement(SidebarItem, { icon: TrackChanges, to: "tech-radar", text: "Tech Radar" ,} )
    , React.createElement(SidebarDivider, null)
    , React.createElement(SidebarItem, { icon: CreateComponentIcon, to: "create", text: "Create",} )
    /* End global nav */
    , React.createElement(SidebarSpace, null )
    , React.createElement(SidebarSignoutComponent, null )
  )
);
