
import {

  createThemeOptions,
  createThemeOverrides,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { createMuiTheme } from '@material-ui/core';
import { ColorPallet, Color } from './apsColor';
import './apsTheme.css';

const themeOptions = createThemeOptions({
  palette: {
    ...lightTheme.palette,
    text: {
      primary: ColorPallet.Brand.color,
      secondary: ColorPallet.Accent.bgColor,
      disabled: ColorPallet.Inactive.bgColor,
      hint: ColorPallet.Info.bgColor
    },
    primary: {
      main: ColorPallet.Accent.bgColor,
    },
    secondary: {
      main: ColorPallet.Secondary.bgColor,
    },
    error: {
      main: ColorPallet.Error.bgColor,
    },
    warning: {
      main: ColorPallet.Warning.bgColor,
    },
    info: {
      main: ColorPallet.Info.bgColor,
    },
    success: {
      main: ColorPallet.Success.bgColor,
    },
    background: {
      default: ColorPallet.Background.bgColor,
      paper: '#FFF',
    },
    banner: {
      info: ColorPallet.Info.color,
      error: ColorPallet.Error.color,
      text: ColorPallet.Primary.color,
      link: ColorPallet.Primary.color,
    },
    errorBackground: ColorPallet.Error.bgColor,
    warningBackground: ColorPallet.Warning.bgColor,
    infoBackground: ColorPallet.Info.bgColor,
    navigation: {
      background: Color.Blue100.bgColor,
      indicator: ColorPallet.Accent.bgColor,
      color: Color.Blue15.bgColor,
      selectedColor: ColorPallet.Primary.color,
    },
  },
  defaultPageTheme: 'home',
  fontFamily: 'Raleway-Regular',
  pageTheme: {
    home: genPageTheme([Color.Lavender60.bgColor, Color.Ruby60.bgColor], shapes.round),
  },
});

const baseTheme = createMuiTheme(themeOptions) ;
const overrides = createThemeOverrides(baseTheme);

export const apsTheme = [
  {
    id: 'light',
    title: 'APS Light Theme',
    variant: 'light',
    theme: { ...baseTheme, overrides },
  },
];
